<template>
  <div v-if="person" class="container-fluid bg-personal-details-cookie">
    <NavQuestion
      :heading="heading"
      :number="number"
      :subHeading="subHeading"
      :questionOptions="true"
      :background="'bg-personal-details'"
    >
      <template v-slot:contents>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="input-container fadeIn-1">
              <p>{{ person }}</p>
            </div>
          </div>
        </div>
      </template>
    </NavQuestion>
  </div>
</template>

<script>
import { http } from '@/services'
import NavQuestion from '@/common/ui/NavQuestion'

export default {
  name: 'ShareRefferal',
  components: {
    NavQuestion
  },
  created() {
    this.data.person_id = this.$route.params.id
  },
  mounted() {
    http
      .get('api/share_person_detail', { params: this.data })
      .then((response) => {
        this.person = response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
  computed: {
    heading() {
      return 'Hi ' + this.person.full_name
    },
    subHeading() {
      return (
        'You have a role in ' + this.person.user.details.full_name + '  Will'
      )
    }
  },
  data() {
    return {
      data: {
        person_id: null
      },
      person: null
    }
  }
}
</script>

<style></style>
